

































































































































































































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { appendSitePrefix } from '@/utils/routeUtils'
import { vxm } from '@/store'

@Component
export default class ReportsProductsCreate extends Vue {
  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
    sendReportForm: {
      validate: any
    }
  }

  private appendSitePrefix = appendSitePrefix
  private loadingData = true
  private showIncrementalFieldsWarning = false

  private pageMode = 'create'
  private filteringPanel = [0]
  private getSampleDataLoading = false
  private gotSampleData = false

  private reportData = []
  private headers = []

  private suppliers = []
  private locations = []
  private mappings = []
  private productTypes = []
  private vehicleTypes = []
  private priceLists = []
  private types = []
  private transferMethods = []
  private transferFrequencies = []
  private transferFileTypes = []

  private _searchTimerId = null
  private productBrandsSearchItems = []
  private productBrandsSearchLoading = false
  private productBrandsSearch = ''

  private name = null
  private filteringFields = []
  private filteringMappingIds = []
  private filteringSupplierIds = []
  private filteringLocationIds = []
  private filteringBrandIds = null
  private filteringProductTypeIds = null
  private filteringVehicleTypeIds = null
  private filteringMinimumInStock = 0
  private priceListId = null
  private typeId = 1

  private testTransferIsLoading = false
  private transferMethodId = null
  private transferFrequencyId = null
  private transferFailNotificationEmails = null
  private transferHost = null
  private transferUser = null
  private transferPassword = null
  private transferPort = null
  private transferEmails = null
  private transferFileTypeId = null

  private showTransferPassword = false

  private saveIsLoading = false

  private pagination = {
    descending: true,
    page: 1,
    itemsPerPage: 15,
    sortBy: [],
  }

  private fields = []

  private saveDataTriggered = false
  private sampleDataTriggered = false

  private get hasTransferMethod() {
    return this.transferMethodId !== null
  }

  private get hasTransferMethodFtp() {
    return this.transferMethodId === 2 || this.transferMethodId === 3
  }

  private get hasTransferMethodEmail() {
    return this.transferMethodId === 1
  }

  private get hasTransferMethodApi() {
    return this.transferMethodId === 4
  }

  private get rules() {
    return {
      name: [(v) => !!v || this.sampleDataTriggered || this.$t('c:validation:This field is required')],
      fields: [(v) => v.length > 0 || this.$t('c:validation:This field is required')],
      suppliers: [
        (v) =>
          (this.filteringSupplierIds && this.filteringSupplierIds.length > 0) ||
          (this.filteringLocationIds && this.filteringLocationIds.length > 0) ||
          this.$t('c:report-products-create-or-edit:You must choose at least a supplier or a location'),
      ],
      locations: [
        (v) =>
          (this.filteringSupplierIds && this.filteringSupplierIds.length > 0) ||
          (this.filteringLocationIds && this.filteringLocationIds.length > 0) ||
          this.$t('c:report-products-create-or-edit:You must choose at least a supplier or a location'),
      ],
      minimumInStock: [(v) => String(v).length > 0 || this.$t('c:validation:This field is required')],
      type: [(v) => !!v || this.sampleDataTriggered || this.$t('c:validation:This field is required')],
      transferMethod: [(v) => !!v || this.sampleDataTriggered || this.$t('c:validation:This field is required')],
      transferFrequency: [(v) => !!v || this.sampleDataTriggered || this.$t('c:validation:This field is required')],
      transferHost: [(v) => !!v || this.sampleDataTriggered || this.$t('c:validation:This field is required')],
      transferUser: [(v) => !!v || this.sampleDataTriggered || this.$t('c:validation:This field is required')],
      transferPassword: [(v) => !!v || this.sampleDataTriggered || this.$t('c:validation:This field is required')],
      transferPort: [(v) => !!v || this.sampleDataTriggered || this.$t('c:validation:This field is required')],
      transferEmails: [(v) => !!v || this.sampleDataTriggered || this.$t('c:validation:This field is required')],
      transferFileType: [(v) => !!v || this.sampleDataTriggered || this.$t('c:validation:This field is required')],
    }
  }

  private created() {
    this.$axios
      .get('/v3/reports/products/create/get_initial_data')
      .then((response) => {
        this.fields = response.data.data.headers
        this.mappings = response.data.data.mappings
        this.suppliers = response.data.data.suppliers
        this.locations = response.data.data.locations
        this.productTypes = response.data.data.productTypes
        this.vehicleTypes = response.data.data.vehicleTypes
        this.priceLists = response.data.data.priceLists
        this.types = response.data.data.types
        this.transferMethods = response.data.data.transferMethods
        this.transferFrequencies = response.data.data.transferFrequencies
        this.transferFileTypes = response.data.data.transferFileTypes
      })
      .finally(async() => {
        if (this.$route.params.id !== undefined) {
          await this.getEditData()
          this.pageMode = 'edit'
        }
        this.loadingData = false
      })
  }

  private getSampleData() {
    this.sampleDataTriggered = true
    if (this.$refs.form.validate()) {
      this.getSampleDataLoading = true
      const data = {
        fields: this.filteringFields,
        mappingIds: this.filteringMappingIds,
        supplierIds: this.filteringSupplierIds,
        locationIds: this.filteringLocationIds,
        brandIds: this.filteringBrandIds,
        productTypeIds: this.filteringProductTypeIds,
        vehicleTypeIds: this.filteringVehicleTypeIds,
        minimumInStock: this.filteringMinimumInStock,
        priceListId: this.priceListId,
        typeId: this.typeId,
        id: this.$route.params.id,
      }
      this.$axios
        .post('/v3/reports/products/create/get_report_data', data)
        .then((response) => {
          this.reportData = response.data.data.reportData
          this.headers = this.fields[this.typeId].filter((field) => {
            return this.filteringFields.includes(field.value)
          })
          this.gotSampleData = true
        })
        .catch((error) => {
          this.reportData = []
          error.response.data.errors.forEach((v) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.getSampleDataLoading = false
        })
    }
    this.sampleDataTriggered = false
  }

  private testTransfer() {
    if (this.$refs.form.validate()) {
      this.testTransferIsLoading = true
      const data = {
        transferMethodId: this.transferMethodId,
        transferHost: this.transferHost,
        transferUser: this.transferUser,
        transferPassword: this.transferPassword,
        transferPort: this.transferPort,
      }
      this.$axios
        .post('/v3/reports/products/create/test_transfer', data)
        .then((response) => {
          vxm.alert.success({
            content: this.$t('c:common:Successfully tested') as string,
            title: this.$t('c:common:Success') as string,
          })
        })
        .catch((error) => {
          error.response.data.errors.forEach((v, i) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.testTransferIsLoading = false
        })
    }
  }

  private async getEditData() {
    await this.$axios
      .get('/v3/reports/products/create/get_edit_data/' + this.$route.params.id)
      .then((response) => {
        const reportData = response.data.data.report
        this.name = reportData.name
        this.filteringFields = reportData.fields
        this.filteringMappingIds = reportData.mappingIds
        this.filteringSupplierIds = reportData.supplierIds
        this.filteringLocationIds = reportData.locationIds
        this.filteringBrandIds = reportData.brandIds
        this.filteringProductTypeIds = reportData.productTypeIds
        this.filteringVehicleTypeIds = reportData.vehicleTypeIds
        this.filteringMinimumInStock = reportData.minimumInStock
        this.priceListId = reportData.priceListId
        this.typeId = reportData.typeId
        this.transferMethodId = reportData.transferMethodId
        this.transferFrequencyId = reportData.transferFrequencyId
        this.transferFailNotificationEmails = reportData.transferFailNotificationEmails
        this.transferHost = reportData.transferHost
        this.transferUser = reportData.transferUser
        this.transferPassword = reportData.transferPassword
        this.transferPort = reportData.transferPort
        this.transferFileTypeId = reportData.transferFileTypeId
        this.transferEmails = reportData.transferEmails
        this.productBrandsSearchItems = response.data.data.productBrands

        if (this.typeId === 2) {
          this.showIncrementalFieldsWarning = true
        }
      })
      .catch((err) => {
        err.response.data.errors.forEach((v, i) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
        this.$router.push({
          name: 'Reports/Products/List',
        })
      })
  }

  private save() {
    this.saveDataTriggered = true
    if (this.$refs.form.validate()) {
      this.saveIsLoading = true
      const data = {
        id: null,
        name: this.name,
        fields: this.filteringFields,
        mappingIds: this.filteringMappingIds,
        supplierIds: this.filteringSupplierIds,
        locationIds: this.filteringLocationIds,
        brandIds: this.filteringBrandIds,
        productTypeIds: this.filteringProductTypeIds,
        vehicleTypeIds: this.filteringVehicleTypeIds,
        minimumInStock: this.filteringMinimumInStock,
        priceListId: this.priceListId,
        typeId: this.typeId,
        transferMethodId: this.transferMethodId,
        transferFrequencyId: this.transferFrequencyId,
        transferFailNotificationEmails: this.transferFailNotificationEmails,
        transferHost: this.transferHost,
        transferUser: this.transferUser,
        transferPassword: this.transferPassword,
        transferPort: this.transferPort,
        transferFileTypeId: this.transferFileTypeId,
        transferEmails: this.transferEmails,
      }
      if (this.isEditPage) {
        data.id = this.$route.params.id
      }
      this.$axios
        .post('/v3/reports/products/create/save', data)
        .then((response) => {
          vxm.alert.success({
            content: this.$t('c:common:Successfully saved') as string,
            title: this.$t('c:common:Success') as string,
          })
          this.$router.push({
            name: 'Reports/Products/List',
          })
        })
        .catch((error) => {
          error.response.data.errors.forEach((v, i) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.saveIsLoading = false
        })
    }
    this.saveDataTriggered = false
  }

  private fetchProductBrandsDebounced(val) {
    clearTimeout(this._searchTimerId)
    this._searchTimerId = setTimeout(() => {
      this.fetchProductBrands(val)
    }, 500) /* 500ms throttle */
  }

  private fetchProductBrands(val) {
    const params = new URLSearchParams()
    this.filteringSupplierIds.forEach((id) => {
      params.append('supplierIds[]', id)
    })
    params.append('query', val)
    this.$axios
      .get('/v4/site/products/brands/search?' + params.toString())
      .then((response) => {
        this.productBrandsSearchItems = response.data.data
      })
      .finally(() => {
        this.productBrandsSearchLoading = false
      })
  }

  @Watch('productBrandsSearch')
  private onProductBrandsSearchChanged(val) {
    // On first time edit opening do not make a search since we have the data prefilled
    if (this.loadingData || val === null || val.length < 3) {
      return
    }

    this.productBrandsSearchLoading = true
    this.fetchProductBrandsDebounced(val)
  }

  private toggleSelectSuppliers() {
    if (this.filteringSupplierIds && this.filteringSupplierIds.length > 0) {
      this.filteringSupplierIds = []
    } else {
      this.filteringSupplierIds = this.suppliers.map(function(a) {
        return a.id
      })
    }
  }

  private get suppliersIcon() {
    if (this.filteringSupplierIds) {
      if (this.filteringSupplierIds.length === this.suppliers.length) {
        return 'fa-check-square'
      }
      if (this.filteringSupplierIds.length > 0) {
        return 'fa-minus-square'
      }
    }
    return 'far fa-square'
  }

  private toggleSelectLocations() {
    if (this.filteringLocationIds && this.filteringLocationIds.length > 0) {
      this.filteringLocationIds = []
    } else {
      this.filteringLocationIds = this.locations.map(function(a) {
        return a.id
      })
    }
  }

  private get locationsIcon() {
    if (this.filteringLocationIds) {
      if (this.filteringLocationIds.length === this.locations.length) {
        return 'fa-check-square'
      }
      if (this.filteringLocationIds.length > 0) {
        return 'fa-minus-square'
      }
    }
    return 'far fa-square'
  }

  private get isEditPage() {
    return this.pageMode === 'edit'
  }
}
